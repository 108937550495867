import gql from 'graphql-tag';

export const DetailsPanelContentLinksQuery = gql`
	query DetailsPanelContentLinksQuery($contentId: ID!, $status: String!, $limit: Int!) {
		singleContent(id: $contentId, status: [$status]) {
			id
			incomingLinks(first: $limit) {
				count
				pageInfo {
					hasNextPage
				}
			}
			outgoingLinks {
				internalOutgoingLinks(first: $limit) {
					count
					pageInfo {
						hasNextPage
					}
				}
				externalOutgoingLinks(first: $limit) {
					count
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
`;
