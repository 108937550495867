import { isSpaceOverview } from '@confluence/named-routes';
import { usePageContentId, usePageSpaceKey, useContentType } from '@confluence/page-context';
import { useSpaceHomepageId } from '@confluence/space-utils';
import { ContentType } from '@confluence/content-types-utils';

type VisibilityKeys<T> = keyof T;

export const ELEMENTS = {
	ROWS: {
		CUSTOM_CONTENT: 'customContent',
		VIEWS: 'views',
		LENGTH: 'length',
		STATUS: 'status',
		REDACTIONS: 'redactions',
		LABELS: 'labels',
	},
	SECTIONS: {
		PROPERTIES: 'properties',
		CONNECTIONS: 'connections',
	},
};

export const SPACE_OVERVIEW_ELEMENT_VISIBILITY = {
	[ELEMENTS.ROWS.CUSTOM_CONTENT]: false,
	[ELEMENTS.SECTIONS.PROPERTIES]: false,
};

export const DATABASE_ELEMENT_VISIBILITY = {
	[ELEMENTS.ROWS.VIEWS]: false,
	[ELEMENTS.ROWS.LENGTH]: false,
	[ELEMENTS.ROWS.STATUS]: false,
	[ELEMENTS.ROWS.REDACTIONS]: false,
	[ELEMENTS.ROWS.LABELS]: false,
	[ELEMENTS.SECTIONS.CONNECTIONS]: false,
};

type SpaceOverviewElementKey = VisibilityKeys<typeof SPACE_OVERVIEW_ELEMENT_VISIBILITY>;

type DatabaseElementKey = VisibilityKeys<typeof DATABASE_ELEMENT_VISIBILITY>;

type ElementKey = SpaceOverviewElementKey | DatabaseElementKey;

export const useElementVisibility = () => {
	const isSpaceOverviewViewMode = isSpaceOverview();
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	const [contentType] = useContentType();
	const homepageId = useSpaceHomepageId(spaceKey);
	const isSpaceOverviewEditMode = homepageId === contentId;
	const isSpaceOverviewPage = isSpaceOverviewViewMode || isSpaceOverviewEditMode;

	const getVisibilityConfig = () => {
		if (isSpaceOverviewPage) return SPACE_OVERVIEW_ELEMENT_VISIBILITY;
		if (contentType === ContentType.DATABASE) return DATABASE_ELEMENT_VISIBILITY;
		return null;
	};

	const isElementVisible = (element: ElementKey) => {
		const visibilityConfig = getVisibilityConfig();

		return (visibilityConfig && visibilityConfig[element]) ?? true;
	};

	return {
		isElementVisible,
	};
};
