import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ArrowUpRightIcon from '@atlaskit/icon/core/arrow-up-right';

import { CONTEXT_PATH } from '@confluence/named-routes';
import { usePageContentId } from '@confluence/page-context';

import { useContentLinks } from '../hooks/useContentLinks';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';
import { SubtleLink } from './SubtleLink';

const i18n = defineMessages({
	outgoingLinks: {
		id: 'details-panel.links-section.outgoing-links-row',
		defaultMessage: 'Outgoing links',
		description:
			'Text that appears next to a number indicating the number of outgoing links on specific content',
	},
});

const linkLimit = 50;

export const OutgoingLinksRow = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();
	const { data, error, refetch } = useContentLinks(linkLimit);
	const {
		internalOutgoingLinksCount,
		internalOutgoingLinksHasMore,
		externalOutgoingLinksCount,
		externalOutgoingLinksHasMore,
	} = data;

	let outgoingLinksCount = internalOutgoingLinksCount + externalOutgoingLinksCount;
	let hasMoreOutgoingLinks = internalOutgoingLinksHasMore || externalOutgoingLinksHasMore;
	if (outgoingLinksCount > linkLimit) {
		outgoingLinksCount = linkLimit;
		hasMoreOutgoingLinks = true;
	}

	if (!outgoingLinksCount && !error) {
		return <div data-testid="outgoing-links-empty-row" />;
	}

	return (
		<DetailRow itemName={formatMessage(i18n.outgoingLinks)} icon={<ArrowUpRightIcon label="" />}>
			{error ? (
				<InlineRetryError error={error} refetch={refetch} />
			) : (
				<SubtleLink href={`${CONTEXT_PATH}/pages/viewinfo.action?pageId=${contentId}`}>
					<div data-testid="outgoing-links-count">
						{outgoingLinksCount}
						{hasMoreOutgoingLinks ? '+' : ''}
					</div>
				</SubtleLink>
			)}
		</DetailRow>
	);
};
