import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

import { DetailsPanelContentLinksQuery } from '../queries/DetailsPanelContentLinksQuery.graphql';
import type {
	DetailsPanelContentLinksQueryVariables as ContentLinksQueryVariables,
	DetailsPanelContentLinksQuery as ContentLinksQuery,
} from '../queries/__types__/DetailsPanelContentLinksQuery';
import { normalizeContentLinksData } from '../normalizers/normalizeContentLinksData';

export const useContentLinks = (linkLimit: number) => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const { data, loading, error, refetch } = useQuery<ContentLinksQuery, ContentLinksQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentLinksQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
				limit: linkLimit,
			},
			skip: isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentLinksData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
	};
};
