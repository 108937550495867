import pick from 'lodash/pick';
import { createStore, createHook, createContainer, createActionsHook } from 'react-sweet-state';

import type { Provider, CollabEditProvider } from '@atlaskit/collab-provider';

type NativeCollabState = {
	collabEditProvider: (CollabEditProvider & Provider) | null;
	collabEditInitialized: boolean;
	hasInitialDraft: boolean;
	isDraftSyncPending: boolean;
	collabEditConnected: boolean;
};

export const initialNativeCollabState: NativeCollabState = {
	collabEditProvider: null,
	collabEditInitialized: false,
	hasInitialDraft: false,
	isDraftSyncPending: false,
	collabEditConnected: false,
};

export const actions = {
	setCollabEditProvider:
		(collabEditProvider) =>
		({ setState }) => {
			setState({
				collabEditProvider,
			});
		},
	setCollabEditInitialized:
		(collabEditInitialized) =>
		({ setState }) => {
			setState({
				collabEditInitialized,
			});
		},
	setHasInitialDraft:
		(hasInitialDraft) =>
		({ setState }) => {
			setState({
				hasInitialDraft,
			});
		},
	setIsDraftSyncPending:
		// PGXT-3849 Remove this if we update lodash to 5.0+ and use debounce.pending() instead


			(isDraftSyncPending) =>
			({ setState }) => {
				setState({
					isDraftSyncPending,
				});
			},
	setCollabEditConnected:
		(collabEditConnected) =>
		({ setState }) => {
			setState({
				collabEditConnected,
			});
		},
	resetNativeCollabState:
		() =>
		({ setState }) => {
			setState({
				collabEditProvider: null,
				collabEditInitialized: false,
				hasInitialDraft: false,
				isDraftSyncPending: false,
				collabEditConnected: false,
			});
		},
};

type NativeCollabStateContainerProps = {
	initialCollabState?: Partial<NativeCollabState>;
};

const Store = createStore({
	initialState: {
		...initialNativeCollabState,
	},
	actions,
	name: 'nativeCollabState',
});

export type NativeCollabActions = typeof actions;

export const NativeCollabStateContainer = createContainer<
	NativeCollabState,
	NativeCollabActions,
	NativeCollabStateContainerProps
>(Store, {
	onInit:
		() =>
		async ({ dispatch }, { initialCollabState }) => {
			initialCollabState?.collabEditProvider &&
				void dispatch(actions.setCollabEditProvider(initialCollabState?.collabEditProvider));
			initialCollabState?.collabEditInitialized &&
				void dispatch(actions.setCollabEditInitialized(initialCollabState?.collabEditInitialized));
			initialCollabState?.hasInitialDraft &&
				void dispatch(actions.setHasInitialDraft(initialCollabState?.hasInitialDraft));
			initialCollabState?.isDraftSyncPending &&
				void dispatch(actions.setIsDraftSyncPending(initialCollabState?.isDraftSyncPending));
			initialCollabState?.collabEditConnected &&
				void dispatch(actions.setCollabEditConnected(initialCollabState?.collabEditConnected));
		},
});

// The properties returned by the selector are those that most likely to change
// only at the start of the collab session.
// The properties that are changing repeatedly during the collab session could be accessed
// through `useNativeCollabDraftSync` and `useNativeCollabConnected` hooks.
// Separating the selectors in this way allows us to avoid unnecessary re-renders of the
// components that are using these hooks.
export const useNativeCollabState = createHook(Store, {
	selector: (state) =>
		pick(state, 'collabEditProvider', 'collabEditInitialized', 'hasInitialDraft'),
});

export const useNativeCollabActions = createActionsHook(Store);

export const useNativeCollabDraftSync = createHook(Store, {
	selector: (state) => pick(state, 'isDraftSyncPending'),
});

export const useNativeCollabConnected = createHook(Store, {
	selector: (state) => pick(state, 'collabEditConnected'),
});
