import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { Inline } from '@atlaskit/primitives';
import PencilIcon from '@atlaskit/icon/core/edit';
import Skeleton from '@atlaskit/skeleton';
import Tooltip from '@atlaskit/tooltip';
import Link from '@atlaskit/link';

import { ProfileCardWrapper } from '@confluence/profile';
import { PEOPLE_DIRECTORY } from '@confluence/named-routes';

import { truncateName } from '../userUtils';
import { useContentLastUpdated } from '../hooks/useContentLastUpdated';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	lastUpdatedBy: {
		id: 'details-panel.properties-section.last-updated-row',
		defaultMessage: 'Last updated by',
		description:
			'A title that accompanies the avatar and name of the user who last updated this content',
	},
	accessibleAvatarLabel: {
		id: 'details-panel.properties-section.last-updated-row.accessible-avatar-label',
		defaultMessage: '{displayName}’s profile picture',
		description: "Alt text and aria-label for a user's profile picture",
	},
});

const MAX_DISPLAY_NAME_LENGTH = 25;
const PEOPLE_DIRECTORY_URL = PEOPLE_DIRECTORY.toUrl();

export const LastUpdatedRow = () => {
	const { formatMessage } = useIntl();
	const { data, error, refetch, loading } = useContentLastUpdated();
	const { accountId, displayName, profilePicturePath } = data;
	const profileURL = accountId ? `${PEOPLE_DIRECTORY_URL}/${accountId}` : '';

	const renderLastUpdatedItem = () => {
		if (loading) {
			return (
				<Skeleton width="80px" height="24px" borderRadius={3} testId="last-updated-row-loading" />
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<Inline alignBlock="center" alignInline="center" space="space.100">
				<Tooltip content={displayName}>
					<Link appearance="subtle" href={profileURL}>
						{truncateName(displayName, MAX_DISPLAY_NAME_LENGTH)}
					</Link>
				</Tooltip>
				<ProfileCardWrapper userId={accountId}>
					<Avatar
						size="small"
						href={profileURL}
						src={profilePicturePath ?? undefined}
						name={formatMessage(i18n.accessibleAvatarLabel, {
							displayName,
						})}
					/>
				</ProfileCardWrapper>
			</Inline>
		);
	};

	return (
		<DetailRow itemName={formatMessage(i18n.lastUpdatedBy)} icon={<PencilIcon label="" />}>
			{renderLastUpdatedItem()}
		</DetailRow>
	);
};
