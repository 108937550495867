import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import { fg } from '@confluence/feature-gating';

import { useElementVisibility, ELEMENTS } from '../hooks/useIsElementVisible';

import { IncomingLinksRow } from './IncomingLinksRow';
import { OutgoingLinksRow } from './OutgoingLinksRow';
import { JiraWorkItemsRow } from './JiraWorkItemsRow';
import { JiraIssuesRow } from './JiraIssuesRow';
import { CustomContentRow } from './CustomContentRow';
import { FilesRow } from './FilesRow';

const i18n = defineMessages({
	connectionsSectionTitle: {
		id: 'details-panel.connections-section.title',
		defaultMessage: 'Connections',
		description:
			'Title for section containing metadata related to connections (links, files, custom content)',
	},
});

const connectionsSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.300',
});

export const ConnectionsSection = () => {
	const { isElementVisible } = useElementVisibility();

	return (
		<Stack testId="connections-section" xcss={connectionsSectionStyles}>
			<FormattedMessage {...i18n.connectionsSectionTitle} tagName="h4" />
			<Stack>
				<IncomingLinksRow />
				<OutgoingLinksRow />
				<FilesRow />
				{isElementVisible(ELEMENTS.ROWS.CUSTOM_CONTENT) && <CustomContentRow />}
				{fg('confluence_jira_issue_metadata_update_count') && <JiraWorkItemsRow />}
				{!fg('confluence_jira_issue_metadata_update_count') &&
					fg('confluence_frontend_details_panel_jira_issues') && <JiraIssuesRow />}
			</Stack>
		</Stack>
	);
};
