import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import { useElementVisibility, ELEMENTS } from '../hooks/useIsElementVisible';

import { CreatedRow } from './CreatedRow';
import { LengthRow } from './LengthRow';
import { ViewsRowWrapper as ViewsRow } from './ViewsRow';

const i18n = defineMessages({
	statsSectionTitle: {
		id: 'details-panel.stats-section.title',
		defaultMessage: 'Stats',
		description:
			'Title for the section containing content statistics, e.g. views, length, creation date',
	},
});

const statsSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.300',
});

export const StatsSection = () => {
	const { isElementVisible } = useElementVisibility();

	return (
		<Stack xcss={statsSectionStyles}>
			<FormattedMessage {...i18n.statsSectionTitle} tagName="h4" />
			<Stack>
				{isElementVisible(ELEMENTS.ROWS.VIEWS) && <ViewsRow />}
				{isElementVisible(ELEMENTS.ROWS.LENGTH) && <LengthRow />}
				<CreatedRow />
			</Stack>
		</Stack>
	);
};
