import { createHook, createStore, createActionsHook, createStateHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

import type { ExtractPresetAPI } from '@atlaskit/editor-common/preset';
import type { useUniversalPreset } from '@atlaskit/editor-core/preset-universal';

export type FullPageEditorPresetAPI = ExtractPresetAPI<ReturnType<typeof useUniversalPreset>>;

export type DetailsPanelEditorState = {
	editorApi?: FullPageEditorPresetAPI;
};

export const initialState: DetailsPanelEditorState = {
	editorApi: undefined,
};

export const actions = {
	setEditorApi:
		(editorApi: FullPageEditorPresetAPI) =>
		({ setState }: StoreActionApi<DetailsPanelEditorState>) => {
			setState({
				editorApi,
			});
		},
};

export const DetailsPanelEditorStateStore = createStore({
	initialState,
	actions,
	name: 'DetailsPanelEditorStateStore',
});

export const useDetailsPanelEditorStore = createHook(DetailsPanelEditorStateStore);

export const useDetailsPanelEditorActions = createActionsHook(DetailsPanelEditorStateStore);

export const useDetailsPanelEditorState = createStateHook(DetailsPanelEditorStateStore);
