import type { DetailsPanelContentLinksQuery } from '../queries/__types__/DetailsPanelContentLinksQuery';

export const normalizeContentLinksData = (data?: DetailsPanelContentLinksQuery) => ({
	incomingLinksCount: data?.singleContent?.incomingLinks?.count ?? 0,
	incomingLinksHasMore: data?.singleContent?.incomingLinks?.pageInfo?.hasNextPage ?? false,
	internalOutgoingLinksCount: data?.singleContent?.outgoingLinks?.internalOutgoingLinks?.count ?? 0,
	internalOutgoingLinksHasMore:
		data?.singleContent?.outgoingLinks?.internalOutgoingLinks?.pageInfo?.hasNextPage ?? false,
	externalOutgoingLinksCount: data?.singleContent?.outgoingLinks?.externalOutgoingLinks?.count ?? 0,
	externalOutgoingLinksHasMore:
		data?.singleContent?.outgoingLinks?.externalOutgoingLinks?.pageInfo?.hasNextPage ?? false,
});
