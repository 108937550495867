import React, { useCallback } from 'react';
import { useObjectSidebar } from '@confluence/object-sidebar-api';
import { css } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import CommentIcon from '@atlaskit/icon/core/comment';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';

import { fg } from '@confluence/feature-gating';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import {
	useCommentsContentState,
	useCommentsContentActions,
	useInlineCommentsContext,
} from '@confluence/comment-context';
import { useShowCommentsPanel } from '@confluence/comments-panel';
import { useCommentsPanel } from '@confluence/comments-panel-utils';
import { UnreadCommentCount } from '@confluence/unread-comments';
import { useAnnotations, useAnnotationsDispatch } from '@confluence/annotation-provider-store';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import {
	GeneralShortcutListener,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
	ShortcutVisualizer,
} from '@confluence/shortcuts';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

const commentIconContainerStyle = css({
	position: 'relative',
});

const i18n = defineMessages({
	openCommentsPanelLabel: {
		id: 'object-sidebar-components.open.comments.panel.label',
		defaultMessage: 'Open Comments Panel',
		description: 'Label for button to open the comments panel',
	},
	showCommentsLabel: {
		id: 'object-sidebar-components.show.comments.label',
		description:
			"The text is shown in a tooltip when the Object Sidebar's Comments button is hovered.",
		defaultMessage: 'Show comments',
	},
	unreadCommentsLabel: {
		id: 'object-sidebar-components.unread.comments.icon.label',
		description: 'aria-label for the comment button to display unread inline comments',
		defaultMessage: 'You have unread comments',
	},
});

export const CommentPanelButton = ({
	contentId,
	collapsed,
}: {
	contentId: string;
	collapsed?: boolean;
}) => {
	const pageMode = useGetPageMode();
	const [{ commentsMarkedAsRead }] = useCommentsPanel();
	const { showCommentsPanel } = useShowCommentsPanel();
	const { viewProps, selectionProps, hoverProps, editorViewProps } = useAnnotations();
	const { setViewProps, setSelectionProps, setHoverProps, setEditorViewProps } =
		useAnnotationsDispatch();
	const [{ panel }, { hideObjectSidebar }] = useObjectSidebar();
	const { hasContentChanged } = useCommentsContentState();
	const { resetContentChanged } = useCommentsContentActions();
	const eventEmitter = getRendererAnnotationEventEmitter();
	const { newCommentHighlight, activeHighlight } = useInlineCommentsContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showModal } = useDialogs();
	const { formatMessage } = useIntl();
	const [{ isViewMode: isLiveViewMode, isEditMode: isLiveEditMode }] = useLivePageMode();

	const handleCommentClick = useCallback(() => {
		// FABRIC PAGES
		// Close the view component if it's open
		if (viewProps) {
			// Inform the Renderer to de-select the annotation
			eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
			eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
			// Remove the display of the comment
			setViewProps(null);
		}

		if (editorViewProps) {
			// Inform the editor to de-select the annotation
			eventEmitter.emit('setselectedannotation', '');
			// Remove the display of the comment
			setEditorViewProps(null);
		}

		if (selectionProps) {
			selectionProps.onClose();
			setSelectionProps(null);
		}

		if (hoverProps) {
			hoverProps.onClose();
			setHoverProps(null);
		}

		// LEGACY PAGES
		if (newCommentHighlight || activeHighlight) {
			// Close the legacy inline comment panel
			window.dispatchEvent(new Event('closeLegacyInlineCommentSidebar'));
		}

		if (panel?.id === 'commentsPanel') {
			hideObjectSidebar();
		} else {
			showCommentsPanel();
		}
	}, [
		eventEmitter,
		viewProps,
		editorViewProps,
		selectionProps,
		hoverProps,
		newCommentHighlight,
		activeHighlight,
		setViewProps,
		setEditorViewProps,
		setSelectionProps,
		setHoverProps,
		showCommentsPanel,
		hideObjectSidebar,
		panel,
	]);

	const checkUnsavedOnClickCommentsButton = useCallback(
		({ triggeredByKeyboardShortcut = false }: { triggeredByKeyboardShortcut?: boolean } = {}) =>
			() => {
				if (hasContentChanged) {
					showModal(CommentWarningDialog, {
						onConfirm: () => {
							resetContentChanged();
							handleCommentClick();
						},
					});
				} else {
					handleCommentClick();
				}

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'commentsPanel',
						source: 'contentWrapper',
						attributes: {
							triggeredByKeyboardShortcut,
						},
					},
				}).fire();
			},
		[hasContentChanged, handleCommentClick, resetContentChanged, showModal, createAnalyticsEvent],
	);

	const shortcutListeners = (
		<>
			{(pageMode === 'view' || isLiveViewMode) && (
				<GeneralShortcutListener
					accelerator={[
						TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
						TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
					]}
					listener={checkUnsavedOnClickCommentsButton({ triggeredByKeyboardShortcut: true })}
				/>
			)}
			{(pageMode === 'edit' || isLiveEditMode) && (
				<GeneralShortcutListener
					accelerator={TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR}
					listener={checkUnsavedOnClickCommentsButton({ triggeredByKeyboardShortcut: true })}
				/>
			)}
		</>
	);

	if (collapsed) {
		return shortcutListeners;
	}

	return (
		<Tooltip
			position="left"
			content={
				<ShortcutVisualizer
					shortcut={TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR}
					contentBefore={formatMessage(i18n.showCommentsLabel)}
					isEditorShortcut={pageMode === 'edit' || isLiveEditMode}
				/>
			}
		>
			<div css={commentIconContainerStyle} data-vc="object-sidebar-comments-button">
				<IconButton
					onClick={checkUnsavedOnClickCommentsButton()}
					appearance="subtle"
					shape={fg('ai-smart-button-team-25') ? 'default' : 'circle'}
					icon={CommentIcon}
					label={formatMessage(i18n.openCommentsPanelLabel)}
					testId="sidebar-comments-button"
				/>
				<UnreadCommentCount
					pageId={contentId}
					// pageMode can be 'view', 'edit' or 'live', but this component only wants view or edit
					pageMode={pageMode === 'view' ? 'view' : 'edit'}
					commentsMarkedAsRead={commentsMarkedAsRead}
				/>
				{shortcutListeners}
			</div>
		</Tooltip>
	);
};
