import React from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import { CreatorRow } from './CreatorRow';
import { ContributorsRow } from './ContributorsRow';
import { LastUpdatedRow } from './LastUpdatedRow';
import { OwnerSection } from './OwnerSection';

const peopleSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
});

const secondaryPeopleStyles = xcss({
	marginTop: 'space.200',
});

export const PeopleSection = () => (
	<Stack xcss={peopleSectionStyles}>
		<OwnerSection />
		<Stack xcss={secondaryPeopleStyles}>
			<ContributorsRow />
			<CreatorRow />
			<LastUpdatedRow />
		</Stack>
	</Stack>
);
