import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

import { DetailsPanelContentLastUpdatedQuery } from '../queries/DetailsPanelContentLastUpdatedQuery.graphql';
import type {
	DetailsPanelContentLastUpdatedQueryVariables as ContentLastUpdatedQueryVariables,
	DetailsPanelContentLastUpdatedQuery as ContentLastUpdatedQuery,
} from '../queries/__types__/DetailsPanelContentLastUpdatedQuery';
import { normalizeContentLastUpdatedData } from '../normalizers/normalizeContentLastUpdatedData';

type UseContentLastUpdatedProps = {
	skip?: boolean;
};

export const useContentLastUpdated = ({ skip = false }: UseContentLastUpdatedProps = {}) => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus({ skip });

	const { data, loading, error, refetch } = useQuery<
		ContentLastUpdatedQuery,
		ContentLastUpdatedQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentLastUpdatedQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
			},
			skip: skip || isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentLastUpdatedData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
	};
};
