import type { DetailsPanelContentLastUpdatedQuery } from '../queries/__types__/DetailsPanelContentLastUpdatedQuery';
import { hasAccountId } from '../userUtils';

export const normalizeContentLastUpdatedData = (data?: DetailsPanelContentLastUpdatedQuery) => {
	const lastUpdatedBy = data?.content?.nodes?.[0]?.history?.lastUpdated?.by;

	return {
		accountId: hasAccountId(lastUpdatedBy) ? lastUpdatedBy.accountId : '',
		displayName: lastUpdatedBy?.displayName ?? '',
		profilePicturePath: lastUpdatedBy?.profilePicture?.path,
	};
};
